<template>
  <section class="home">
    <Loader :loading="loading"></Loader>
    <div class="home__header">
      <img
        class="home__header-image"
        :src="featuredExhibition.slug ? featuredExhibition.home_page_image : home_page_image"
        alt="art"
      />
      <div class="home__header-container">
        <div class="home__header-container-content">
          <p class="home__header-sub-title text-sub-title" v-if="featuredExhibition.slug != null">
            {{ featuredExhibition.dates }} | {{ featuredExhibition.exhibition_type }}
          </p>
          <h2 class="home__header-title text-title">
            {{
              featuredExhibition.slug
                ? featuredExhibition.title
                : "browngrotta arts: international art textiles, mixed media and dimensional art"
            }}
          </h2>
          <div class="home__header-buttons">
            <BaseButton
              class="home__header-button button--white"
              v-if="featuredExhibition.slug"
              text="PLAN YOUR VISIT"
              @click="$router.push({ path: `/exhibitions/${featuredExhibition.slug}` })"
            ></BaseButton>
            <BaseButton
              class="home__header-button button--white"
              v-else
              text="ABOUT GALLERY"
              @click="$router.push({ path: '/about' })"
            ></BaseButton>
            <BaseButton
              class="home__header-button button--outline-white"
              text="ALL EXHIBITIONS"
              @click="$router.push({ path: '/exhibitions' })"
            ></BaseButton>
          </div>
          <Copyright class="home__header-copyright">
            {{ featuredExhibition.copyright ? featuredExhibition.copyright : "Tom Grotta" }}
          </Copyright>
        </div>

        <!-- <IconScroll class="home__icon-scroll" /> -->
        <SharePlatforms class="home__share-platforms" />
      </div>
    </div>
    <div class="home__artworks">
      <div class="home__artwork" v-for="(artwork, key) in artworks" :key="`artwork-${key}`">
        <div
          class="home__artwork-image"
          :style="{
            backgroundImage: `url(${artwork.search_image})`,
          }"
          :alt="artwork.title"
        ></div>
        <div
          class="home__artwork-container"
          @click="$router.push({ path: `/artworks/${artwork.slug}` })"
        >
          <p class="home__artwork-text text-sub-title">Click to View</p>
          <h3 class="home__artwork-title text-title">{{ artwork.title }}</h3>
          <!-- <span class="home__artwork-price">{{ artwork.price }}</span> -->
          <div class="home__artwork-sub-title">
            <p class="home__artwork-sub-title--text">
              {{ artwork.artist }}
            </p>
            <!-- <span class="home__artwork-sub-title--code">{{ artwork.sku }}</span> -->
          </div>
        </div>
      </div>
    </div>
    <InfiniteLoading class="infinite-loading" @infinite="loadMore"></InfiniteLoading>
    <div class="home__footer" v-if="hasMorePages">
      <BaseButton class="button--white" text="LOAD MORE ARTWORKS" @click="loadMore"></BaseButton>
    </div>
    <div style="padding: 15px 0"></div>
  </section>
</template>

<script>
import home_page_image from "@/assets/images/homepage_background.jpg";

import Loader from "@/components/Loader.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import IconScroll from "@/components/icons/IconScroll.vue";
import SharePlatforms from "@/components/SharePlatforms.vue";
import InfiniteLoading from "vue-infinite-loading";
import Copyright from "@/components/Copyright.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Loader,
    BaseButton,
    IconScroll,
    SharePlatforms,
    InfiniteLoading,
    Copyright,
  },
  data() {
    return {
      loading: false,
      busy: false,
      page: 1,
      home_page_image,
    };
  },
  head: {
    title: function () {
      return {
        inner: "Home",
      };
    },
    meta: function () {
      return [
        {
          name: "description",
          itemprop: "description",
          content: `A leading contemporary art gallery specializing in international art textiles, fiber sculpture, ceramics, and mixed media.`,
          id: "meta:description",
        },
        {
          itemprop: "name",
          content: "Home browngrotta arts",
          id: "meta:name",
        },
        {
          itemprop: "image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "meta:image",
        },
        {
          property: "og:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "og:url",
        },
        {
          property: "og:type",
          content: "website",
          id: "og:type",
        },
        {
          property: "og:title",
          content: "Home browngrotta arts",
          id: "og:title",
        },
        {
          property: "og:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "og:image",
        },
        {
          property: "og:image:alt",
          content: "Home browngrotta arts",
          id: "og:image:alt",
        },
        {
          property: "og:description",
          content: `A leading contemporary art gallery specializing in international art textiles, fiber sculpture, ceramics, and mixed media.`,
          id: "og:description",
        },
        {
          property: "og:site_name",
          content: process.env.VUE_APP_FRONTEND_URL,
          id: "og:site_name",
        },
        {
          property: "og:locale",
          content: "en_US",
          id: "og:locale",
        },
        {
          name: "twitter:card",
          content: "summary",
          id: "twitter:card",
        },
        {
          name: "twitter:site",
          content: "@BrowngrottaArts",
          id: "twitter:site",
        },
        {
          name: "twitter:creator",
          content: "@BrowngrottaArts",
          id: "twitter:creator",
        },
        {
          name: "twitter:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "twitter:url",
        },
        {
          name: "twitter:title",
          content: "browngrotta arts",
          id: "twitter:title",
        },
        {
          name: "twitter:description",
          content: `A leading contemporary art gallery specializing in international art textiles, fiber sculpture, ceramics, and mixed media.`,
          id: "twitter:description",
        },
        {
          name: "twitter:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "twitter:image",
        },
        {
          name: "twitter:image:alt",
          content: "browngrotta arts",
          id: "twitter:image:alt",
        },
      ];
    },
  },
  async created() {
    this.loading = true;
    await this.apiGetFeaturedExhibition();
    await this.apiGetArtworks({
      page: this.page,
      exhibition_id: this.featuredExhibition.id ? this.featuredExhibition.id : false,
    });
    this.loading = false;
  },
  computed: {
    ...mapGetters("publicapi/home", {
      artworks: "getArtworks",
      hasMorePages: "getHasMorePages",
      featuredExhibition: "getFeaturedExhibition",
    }),
  },
  methods: {
    ...mapActions("publicapi/home", ["apiGetArtworks", "apiGetFeaturedExhibition"]),
    async loadMore($state) {
      this.busy = true;
      if (this.hasMorePages) {
        this.page += 1;
        await this.apiGetArtworks({ page: this.page });
        $state?.loaded();
        this.busy = false;
      } else {
        $state?.complete();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  min-width: 100vw;
  min-height: 100vh;
  background-color: $lightBlack;
  &__header {
    position: relative;
    width: 100vw;
    height: 100vh;
    background-color: $lightBlack;
    &-image {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 50%;
      transform: translate(-50%, 0);
      object-fit: cover;
      @media screen and (max-width: $xxs) {
        width: auto;
        max-width: none;
      }
    }
    &-container {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      width: 100%;
      //padding: 23rem 10rem 0 31.5rem;
      padding: 12rem 0;
      background-color: rgba($color: $black, $alpha: 0.45);
      &-content {
        width: 70%;
        padding: 0 30px;
        margin: 0 auto;
        @media only screen and (max-width: $md) {
          width: 100%;
          padding: 0 12rem 0 5rem;
        }
        @media only screen and (max-width: $sm) {
          padding: 0 12rem 0 25px;
        }
        @media screen and (max-width: $xxs) {
        }
      }
    }
    &-title {
      font-size: 5.2rem;
      line-height: 1.55;
      margin-bottom: 30px;
      max-width: 68rem;
      @media only screen and (max-width: $md) {
        max-width: 75%;
        font-size: 35px;
        margin-bottom: 20px;
      }
      @media screen and (max-width: $xxs) {
        max-width: 100%;
      }
    }
    &-sub-title {
      margin-bottom: 5px;
      font-size: 1.8rem;
      line-height: 1.5;
      text-transform: uppercase;
      @media screen and (max-width: $xs) {
        font-size: 14px;
      }
    }
    &-buttons {
      margin-top: 2rem;
      display: flex;
    }
    &-button {
      margin-right: 15px;
    }
    &-copyright {
      position: absolute;
      bottom: 13.5rem;
      left: 10.5rem;
      &::v-deep .copyright,
      &.copyright {
        font-size: 24px;
        font-weight: 400;
        line-height: 1;
      }
      @media only screen and (max-width: $md) {
        left: 5rem;
      }
      @media only screen and (max-width: $sm) {
        left: 25px;
      }
      @media screen and (max-width: $xxs) {
        bottom: 7rem;
      }
    }
  }
  &__icon-scroll {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0);
    @media only screen and (max-width: $md) {
      bottom: -5rem;
    }
    @media screen and (max-width: $xxs) {
      bottom: -7rem;
    }
  }
  &__artworks {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 7px;
    grid-row-gap: 7px;
    margin-top: -2rem;
    @media screen and (max-width: $xxs) {
      grid-template-columns: 1fr;
      grid-column-gap: 0;
      grid-row-gap: 7px;
      margin-top: 0;
    }
  }
  &__artwork {
    position: relative;
    height: 33.33vw;
    &:nth-of-type(3n + 1) {
      margin-top: -8rem;
    }
    /*&:nth-of-type(3n + 2) {
      margin-top: -20px;
    }*/
    &:nth-of-type(3n) {
      margin-top: -16rem;
    }
    &:hover {
      .home__artwork-container {
        opacity: 1;
        background-color: rgba($color: $blue, $alpha: 0.83);
      }
    }
    @media screen and (max-width: $xxs) {
      height: 100vw;
      &:nth-of-type(n) {
        margin-top: 0;
      }
    }
    &-image {
      position: absolute;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
    }
    &-container {
      cursor: pointer;
      position: absolute;
      width: 100%;
      height: 100%;
      padding: 10px 10px 3rem 3rem;
      background-color: rgba($color: $blue, $alpha: 0.83);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      transition: all 0.3s ease;
      opacity: 0;
      @media screen and (max-width: $xxs) {
        opacity: 1;
        background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
      }
      @media only screen and (max-width: $md) {
        padding: 10px;
      }
    }
    &-text {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 3rem;
      line-height: 1.67;
      letter-spacing: -1px;
      @media only screen and (max-width: $md) {
        font-size: 18px;
      }
      @media only screen and (max-width: $sm) {
        font-size: 15px;
      }
      @media screen and (max-width: $xxs) {
        opacity: 0;
      }
    }
    &-title {
      font-size: 4rem;
      line-height: 1.25;
      letter-spacing: -1.33px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-clamp: 2;
      text-overflow: clip;
      overflow: hidden;
      @media only screen and (max-width: $xl) {
        font-size: 32px;
      }
      @media only screen and (max-width: $md) {
        font-size: 25px;
      }
      @media only screen and (max-width: $sm) {
        font-size: 20px;
      }
    }
    // &-price {
    //   font-size: 3.125rem;
    //   line-height: 1.55;
    //   margin-bottom: 8px;
    //   @media only screen and (max-width: $xl) {
    //     font-size: 20px;
    //   }
    // }
    &-sub-title {
      display: flex;
      justify-content: space-between;
      &--text {
        font-size: 2.35rem;
        line-height: 1.3;
        letter-spacing: -1px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        line-clamp: 1;
        text-overflow: clip;
        overflow: hidden;
        @media only screen and (max-width: $xl) {
          font-size: 20px;
        }
        @media only screen and (max-width: $md) {
          font-size: 18px;
        }
        @media only screen and (max-width: $sm) {
          font-size: 16px;
        }
      }
      // &--code {
      //   display: flex;
      //   justify-content: center;
      //   align-content: center;
      //   text-transform: uppercase;
      //   border: 1px solid $white;
      //   border-radius: 5px;
      //   background-color: rgba(0, 0, 0, 0.45);
      //   min-width: 7.2rem;
      //   font-size: 1.4rem;
      //   line-height: 2.5rem;
      //   padding: 0.1rem 1rem;
      //   z-index: 2;
      //   max-width: 30%;
      //   display: -webkit-box;
      //   display: block;
      //   white-space: nowrap;
      //   overflow: hidden;
      //   text-overflow: ellipsis;
      //   &:empty {
      //     display: none;
      //   }
      // }
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7.6rem 25px 24rem 25px;
  }
  &__share-platforms {
    position: absolute;
    right: 10rem;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    @media screen and (max-width: $md) {
      right: 5.5rem;
    }
    @media screen and (max-width: $sm) {
      right: 3.5rem;
    }
  }
}
</style>
